/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner"; 
import OrderSuccess from "../notifcations/OrderSuccess";
import { RiEdit2Line } from "react-icons/ri";
import {
  fetchProducts,
  sendOrder,
  setIsProcessed,
  setProducts,
  setIsLoading,
  setEditableQuantities,
  setAtp,
} from "../../redux/reducers/routineOrderSlice";
import { useDispatch, useSelector } from "react-redux";

const RoutineOrder = () => {
  const { itemId } = useParams();
  const dispatch = useDispatch();
  const { products, isLoading, isProcessed } = useSelector(
    (state) => state.routineOrder
  );
  const atp = useSelector((state) => state.routineOrder.atp);
  console.log(atp);
  const editableQuantities = useSelector(
    (state) => state.routineOrder.editableQuantities
  );

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch products only if products array is empty
    if (products.length === 0) {
      dispatch(fetchProducts(itemId));
    }
  }, [dispatch, itemId, products]);

 
  const toggleEditableMode = (itemId) => {
    dispatch(
      setEditableQuantities({ itemId, newQty: !editableQuantities[itemId] })
    );
  };

  const handleQuantityChange = (itemId, newQty) => {
    dispatch(setEditableQuantities({ itemId, newQty }));
  };

  const handleCheckboxChange = (itemId, value) => {
    dispatch(setAtp({ productId: itemId, value }));
  };

  const handleSendOrder = async () => {
    try {
      const token = localStorage.getItem("token");
      dispatch(
        sendOrder({
          itemId,
          products: products.map((item) => ({
            id: item.id,
            name: item.name,
            qty: editableQuantities[item.id] || item.qty,
            atp: atp[item.id],
          })),
        })
      );
      dispatch(setIsProcessed(true));
    } catch (error) {
      toast.error("Failed to send order");
    }
  };

  const handleOrderProcessed = () => {
    if (isProcessed) {
      navigate("/dashboard");
    }
  };

  const handleClick = () => {
    const allProductsSelected = products.every(
      (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
    );

    const allQuantitiesAvailable = products.every(
      (item) => parseInt(item.available_qty) >= parseInt(item.qty)
    );

    const allEditableQuantitiesValid = products.every(
      (item) =>
        !editableQuantities[item.id] || editableQuantities[item.id] <= item.qty
    );

    if (
      allProductsSelected &&
      allQuantitiesAvailable &&
      allEditableQuantitiesValid &&
      !isProcessed
    ) {
      handleSendOrder();
    } else if (isProcessed) {
      handleOrderProcessed();
    } else {
      // Display error toast when editableQuantities > item.qty for every item
      if (
        products.some(
          (item) =>
            editableQuantities[item.id] &&
            editableQuantities[item.id] > item.qty
        )
      ) {
        toast.error(`Invalid quantities, please check the quantities`);
      }
    }
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  if (isProcessed) {
    return <OrderSuccess notification={"Order Successful"} route={"orders"} />;
  }

  return (
    <div className="mt-10 p-6">
      <div className="flex justify-end items-center">
        <button
          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm cursor-pointer"
          onClick={handleBack}
        >
          Back to dashboard
        </button>
      </div>
      <div className="flex justify-center items-center h-[80vh]">
        <div className="w-full max-w-4xl  px-10">
          <div className="flex flex-col mt-8">
            {isLoading ? (
              <LoadingSpinner />
            ) : products.length === 0 ? (
              <p className="text-gray-400 text-center mb-4">
                No products available
              </p>
            ) : (
              <div className="overflow-x-auto">
                <div className="flex justify-between items-center mb-4">
                  <h1 className="text-2xl font-bold text-center w-full">
                    Routine request
                  </h1>
                </div>
                <table className="w-full table-auto mb-16">
                  <thead className="bg-[#06652e]">
                    <tr className="text-[#fff] text-base">
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Product
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Formulation
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Quantity
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Available Qty
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">ATP</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((item, index) => (
                      <tr key={item.id} className="text-center border-b-[1px]">
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 ">
                          {item.name}
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          {item.formulation}
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          <div className="flex items-center justify-center">
                            {editableQuantities[item.id] ? (
                              <input
                                className="w-14 text-center bg-white"
                                type="number"
                                value={editableQuantities[item.id] || item.qty}
                                onChange={(e) =>
                                  handleQuantityChange(item.id, e.target.value)
                                }
                              />
                            ) : (
                              item.qty
                            )}
                            <div
                              className="cursor-pointer ml-2"
                              onClick={() => toggleEditableMode(item.id)}
                            >
                              <RiEdit2Line />
                            </div>
                          </div>
                        </td>
                        <td
                          className={`py-4 px-2 sm:px-4 md:px-6 lg:px-8 ${
                            parseInt(item.available_qty) >= parseInt(item.qty)
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {item.available_qty}
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex items-center mr-4">
                              <input
                                type="checkbox"
                                name={`checkbox_${item.id}`}
                                className="form-radio h-5 w-5 text-blue-600"
                                checked={atp[item.id] === "Yes"}
                                onChange={() =>
                                  handleCheckboxChange(item.id, "Yes")
                                }
                              />
                              <span className="ml-2">Yes</span>
                            </label>
                            <label className="inline-flex items-center">
                              <input
                                type="checkbox"
                                name={`checkbox_${item.id}`}
                                className="form-radio h-5 w-5 text-blue-600"
                                checked={atp[item.id] === "No"}
                                onChange={() =>
                                  handleCheckboxChange(item.id, "No")
                                }
                              />
                              <span className="ml-2">No</span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {isLoading ? null : products.length === 0 ? (
              <p className="text-gray-400 text-center mb-4">
                No quantity available
              </p>
            ) : (
              <button
                className={`px-4 py-4 rounded w-full ${
                  isProcessed
                    ? "bg-[#06652e] text-white font-normal"
                    : "bg-[#06652e] text-white font-normal"
                }`}
                onClick={handleClick}
                disabled={
                  !products.every(
                    (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
                  ) ||
                  !products.every(
                    (item) => parseInt(item.available_qty) >= parseInt(item.qty)
                  ) ||
                  isProcessed
                }
                style={
                  !products.every(
                    (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
                  ) ||
                  !products.every(
                    (item) => parseInt(item.available_qty) >= parseInt(item.qty)
                  ) ||
                  isProcessed
                    ? { opacity: 0.5, cursor: "not-allowed" }
                    : {}
                }
              >
                {isProcessed ? "Processed" : "Send Order"}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoutineOrder;




