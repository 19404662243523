import React from "react";
// import sidebarIcon from "../assets/SidebarIcon.svg";
import { ReactComponent as DashboardIcon } from "../assets/DashIcon2.svg";
import { ReactComponent as InventoryIcon2 } from "../assets/InventoryIcon2.svg";
import { ReactComponent as FacilitiesIcon } from "../assets/AddIcon.svg";
import { ReactComponent as SettingsIcon } from "../assets/Settings.svg";
import { useState, useEffect } from "react";
import "../components/styles/Sidebar.css";
import { NavLink, useLocation } from "react-router-dom";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineCompareArrows } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { TbPackages } from "react-icons/tb";
import { FiSettings } from "react-icons/fi";
import { FiBox } from "react-icons/fi";
import { TbPackageExport } from "react-icons/tb";
import {MdHistory} from "react-icons/md";

const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`w-[256px] flex flex-col bg-gray-50 text-gray-700 overflow-hidden ${
          isMobile && "hidden"
        } fixed h-screen`}
      >
        <div className="flex items-center justify-start p-6 h-16 mt-6">
          <FiBox className="h-8 w-8 mr-2 text-[#06652e]" />
          <a
            href="/dashboard"
            className="text-lg font-bold ml-2 text-[#06652e] no-underline"
          >
            OneBox
          </a>
        </div>
        <ul className="flex-grow text-left">
          {[
            {
              label: "Dashboard",
              icon: MdOutlineDashboard,
              activeIcon: MdOutlineDashboard,
              path: "/dashboard",
            },
            {
              label: "Inventory",
              icon: TbPackages,
              activeIcon: TbPackages,
              path: "/inventory",
            },
            {
              label: "Facilities",
              icon: MdOutlineHomeWork,
              activeIcon: MdOutlineHomeWork,
              path: "/facilities",
            },
            {
              label: "Orders",
              icon: TbPackageExport,
              activeIcon: TbPackageExport,
              path: "/orders",
            },

            {
              label: "Order History",
              icon: MdHistory,
              activeIcon: MdHistory,
              path: "/order/history",
            },
            {
              label: "Compare Facilities",
              icon: MdOutlineCompareArrows,
              activeIcon: MdOutlineCompareArrows,
              path: "/compare-facilities",
            },
            {
              label: "Settings",
              icon: FiSettings,
              activeIcon: FiSettings,
              path: "/settings",
            },
          ].map(({ label, icon: Icon, activeIcon: ActiveIcon, path }) => (
            <li
              key={path}
              className={`my-2 flex justify-start items-center p-6 gap-3 ${
                location.pathname === path ? "active-link font-semibold" : ""
              }`}
            >
              {location.pathname === path ? (
                <ActiveIcon className="h-8 w-8 mr-1" />
              ) : (
                <Icon className="h-8 w-8 mr-1" />
              )}
              <NavLink
                to={path}
                className={`${
                  location.pathname === path ? "text-#06652e" : ""
                }`}
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
        {/* <div className="p-6">
          <img
            src={sidebarIcon}
            className="object-contain h-auto mt-4"
            alt=""
          />
        </div> */}
      </div>

      {isMobile && (
        <div className="footer flex justify-center items-center bg-[#F8FBFF] fixed bottom-0 w-full h-16 border-t border-gray-300 p-6 overflow-x-auto overflow-y-hidden">
          {/* Footer content */}

          <div className="flex items-center justify-center gap-4 mx-10">
            <NavLink
              to="/dashboard"
              className="flex flex-col items-center justify-center"
            >
              <DashboardIcon
                className={`h-6 w-6 ${
                  location.pathname === "/dashboard" ? "active-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/dashboard" ? "active-link" : ""
                }`}
              >
                Dashboard
              </p>
            </NavLink>

            <NavLink
              to="/inventory"
              className="flex flex-col items-center justify-center"
            >
              <InventoryIcon2
                className={`h-6 w-6 ${
                  location.pathname === "/inventory" ? "active-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/inventory" ? "active-link" : ""
                }`}
              >
                Inventory
              </p>
            </NavLink>

            <NavLink
              to="/facilities"
              className="flex flex-col items-center justify-center"
            >
              <FacilitiesIcon
                className={`h-6 w-6 ${
                  location.pathname === "/facilities" ? "active-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/facilities" ? "active-link" : ""
                }`}
              >
                Facilities
              </p>
            </NavLink>

            <NavLink
              to="/orders"
              className="flex flex-col items-center justify-center"
            >
              <FacilitiesIcon
                className={`h-6 w-6 ${
                  location.pathname === "/orders" ? "aactive-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/orders" ? "active-link" : ""
                }`}
              >
                Orders
              </p>
            </NavLink>

            <NavLink
              to="/orders"
              className="flex flex-col items-center justify-center"
            >
              <FacilitiesIcon
                className={`h-6 w-6 ${
                  location.pathname === "/orders" ? "aactive-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/orders" ? "active-link" : ""
                }`}
              >
                Order History
              </p>
            </NavLink>

            <NavLink
              to="/compare-facilities"
              className="flex flex-col items-center justify-center"
            >
              <FacilitiesIcon
                className={`h-6 w-6 ${
                  location.pathname === "/compare-facilities"
                    ? "aactive-link"
                    : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/compare-facilities"
                    ? "active-link"
                    : ""
                }`}
              >
                Compare
              </p>
            </NavLink>

            <NavLink
              to="/settings"
              className="flex flex-col items-center justify-center"
            >
              <SettingsIcon
                className={`h-6 w-6 ${
                  location.pathname === "/settings" ? "active-link" : ""
                }`}
              />
              <p
                className={`text-xs ${
                  location.pathname === "/settings" ? "active-link" : ""
                }`}
              >
                Settings
              </p>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
