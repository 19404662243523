import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner";
import OrderSuccess from "../../components/notifcations/OrderSuccess";
import axios from "axios";
import {
  fetchProducts,
  setProducts,
  setIsLoading,
  setIsProcessed,
  sendOrder,
  setFacility,
  setAtp,
} from "./../../redux/reducers/requestOrders";
import { fetchFacility } from "./../../redux/reducers/requestOrders";
import { RiEdit2Line } from "react-icons/ri";
import { toast } from "react-toastify";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import * as XLSX from 'xlsx';

const EmergencyOrder = () => {
  const { itemId, type } = useParams();
  const pdfRef = useRef();
  // console.log("type", itemId, type);
  const dispatch = useDispatch();
  const { products, isLoading, isProcessed, facility } = useSelector(
    (state) => state.emergencyOrder
  );
  const atp = useSelector((state) => state.emergencyOrder.atp);

  // console.log(atp);
  const [editableQuantities, setEditableQuantities] = useState({});
  const [productMode, setProductMode] = useState([]);
  const [editableProducts, setEditableProducts] = useState({});
  const [editPrice, setEditPrice] = useState({});

  const [sheet, setSheet] = useState([]);
  // console.log("sheet", sheet)
  // console.log("pro", productMode);
  // console.log("okayokay:", editableProducts);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchProducts(itemId));
    dispatch(fetchFacility(itemId));

    return () => {
      dispatch(setProducts([]));
      dispatch(setFacility({}));
      dispatch(setIsLoading(true));
      dispatch(setIsProcessed(false));
    };
  }, [dispatch, itemId]);


  //Product with editable product
  const handleSendOrder = async () => {
    const productsWithEditedQuantity = products.map((item) => ({
      ...item,
      qty: editableQuantities[item.id] || item.qty,
      atp: atp[item.id] || item.atp,
    }));

    dispatch(
      sendOrder({
        itemId,
        products: productsWithEditedQuantity,
        atp: atp,
      })
    );
  };

   //Product with editable price
  //  

  //Qtty
  const toggleEditableMode = (productId) => {
    setEditableQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: !prevQuantities[productId],
    }));
  };

  //Price
  const toggleEditPrice = (productId) => {
    setEditPrice((prevPrices) => ({
      ...prevPrices,
      [productId]: !prevPrices[productId]
    }))
  };

  //Product
  const toggleEditMode = (productId) => {
    setEditableProducts((prevEditable) => ({
      ...prevEditable,
      [productId]: !prevEditable[productId],
    }));
  };

  const handleOrderProcessed = () => {
    if (isProcessed) {
      navigate("/orders");
    }
  };

  // //Download PDF
  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4", true);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(
  //       imgData,
  //       "PNG",
  //       imgX,
  //       imgY,
  //       imgWidth * ratio,
  //       imgHeight * ratio
  //     );
  //     pdf.save("products.pdf");
  //   });
  // };

  const fetchExcel = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://kasdhma.lifebanktest.com/api/admin/download/${itemId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const data = response.data.data
      setSheet(data)
    }catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchExcel()
  }, []);


  

  const exportExcel = () => {
    // console.log("hello", sheet);

    // Check if sheet is not empty
    if (sheet.length > 0) {
        // Create an array of subsets for each row in the sheet
        const productsSubset = sheet.map((row) => ({
            'Customer code': row.Customer_code,
            'Customer name': row.customer_name,
            'item code': row.product_code,
            'item name': row.name,
            'Customer current stock on hand': row.current_stock,
            'Quantity requested': row.qty
        }));

        var wb = XLSX.utils.book_new(),
        wsProducts = XLSX.utils.json_to_sheet(productsSubset);

        XLSX.utils.book_append_sheet(wb, wsProducts, "Products " + itemId);
        var fileName = `Product_${itemId}.xlsx`;

        XLSX.writeFile(wb, fileName);
    } else {
        console.warn("Sheet is empty. No data to export.");
    }
};




  const handleClick = () => {
    // const allProductsSelected = products.every(
    //   (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
    // );

    const allQuantitiesAvailable = products.every(
      (item) => parseInt(item.available_qty) >= parseInt(item.qty)
    );

    const allEditableQuantitiesValid = products.every(
      (item) =>
        !editableQuantities[item.id] || editableQuantities[item.id] <= item.qty
    );

    if (
      // allProductsSelected &&
      allQuantitiesAvailable &&
      allEditableQuantitiesValid &&
      !isProcessed
    ) {
      handleSendOrder();
    } else if (isProcessed) {
      handleOrderProcessed();
    } else {
      // Display error toast when editableQuantities > item.qty for every item
      if (
        products.some(
          (item) =>
            editableQuantities[item.id] &&
            editableQuantities[item.id] > item.qty
        )
      ) {
        toast.error(`Invalid quantities, please check the quantities`);
      }
    }
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  

  const fetchList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://kasdhma.lifebanktest.com/api/admin/inventory/my/list",
        {
          headers: {
            Authorization: token,
          },
        }
      );

      const responseData = response.data;
      // console.log("responseData", responseData)
      if (responseData.status) {
        // console.log(responseData.data);
        setProductMode(responseData.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  if (isProcessed) {
    return <OrderSuccess notification={"Order Successful"} route={"orders"} />;
  }
  // console.log(products);
  return (
    <div className="mt-10 p-6 h-screen">
      <div className="flex justify-between items-center">
        <button
          className="bg-[#06652e] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm cursor-pointer"
          onClick={handleBack}
        >
          Back to dashboard
        </button>

        <button
          className="bg-[#6F6F6F] hover:bg-[#06652e] text-[#F8FBFF] font-normal py-2 px-4 rounded text-sm cursor-pointer"
          // onClick={downloadPDF}
          onClick={exportExcel}
        >
          Download
        </button>
      </div>

      <div className="flex justify-center items-center ">
        <div className="w-full max-w-6xl px-10">
          <div className="flex flex-col mt-8"></div>
        </div>
      </div>

      <div className="flex justify-center items-center ">
        <div className="w-full max-w-6xl px-10">
          <div className="flex flex-col mt-8" ref={pdfRef}>
            {isLoading ? (
              <LoadingSpinner />
            ) : facility.length === 0 ? (
              <p className="text-gray-400 text-center mb-4">
                No facility available
              </p>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full table-auto mb-16 shadow-lg">
                  <thead className="bg-[#6F6F6F]">
                    <tr className="text-[#fff] text-base">
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Facility Name
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Facility Codes
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Order Type:
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Address
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        City
                      </th>
                      {/* <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">ATP</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {facility.map((item, index) => { */}
                    <tr className="text-center border-b-[1px]">
                      <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        {facility.name}
                      </td>
                      <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        {facility.id}
                      </td>
                      <td
                        className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 text-color-[#b70303]"
                        style={{ color: "#b70303" }}
                      >
                        {"Emergency"}
                      </td>
                      <td>{facility.address}</td>
                      <td>{facility.city}</td>
                    </tr>
                    {/* })}  */}
                  </tbody>
                </table>
              </div>
            )}

            {isLoading ? (
              <LoadingSpinner />
            ) : products.length === 0 ? (
              <p className="text-gray-400 text-center mb-4">
                No products available
              </p>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full table-auto mb-16 ">
                  <thead className="bg-[#06652e]">
                    <tr className="text-[#fff] text-base">
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Product Code
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Product
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Formulation
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Price
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Quantity
                      </th>
                      <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                        Available Qty
                      </th>
                      {/* <th className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">ATP</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((item, product, index) => (
                      <tr key={item.id} className="text-center border-b-[1px]">
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                         {item.product_code}
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          {/* {item.name} */}

                          <div className="flex items-center justify-center">
                            {editableProducts[item.id] ? (
                              <select
                                value={
                                  editableProducts[item.id] || product.product
                                }
                                onChange={(e) =>
                                  setEditableProducts((prevEditable) => ({
                                    ...prevEditable,
                                    [item.id]: e.target.value,
                                  }))
                                }
                                className="max-w-md h-15 p-5 text-wrap outline-none rounded"
                              >
                                {productMode.map((product) => (
                                  <option key={product.id} value={product.id}>
                                    {product.product}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <div className="flex items-center">
                                {item.name}
                                <div
                                  className="cursor-pointer ml-2"
                                  onClick={() => toggleEditMode(item.id)}
                                >
                                  <RiEdit2Line />
                                </div>
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          {item.formulation}
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          <div className="flex items-center justify-center">
                            {editPrice[item.id] ? (
                              <input
                                className="w-14 text-center"
                                type="number"
                                value={editPrice[item.id] || item.price}
                                onChange={(e) =>
                                  setEditPrice((prevQuantities) => ({
                                    ...prevQuantities,
                                    [item.id]: e.target.value,
                                  }))
                                }
                              />
                            ) : (
                              item.price
                            )}

                            <div
                              className="cursor-pointer ml-2"
                              onClick={() => toggleEditPrice(item.id)}
                            >
                              <RiEdit2Line />
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          <div className="flex items-center justify-center">
                            {editableQuantities[item.id] ? (
                              <input
                                className="w-14 text-center"
                                type="number"
                                value={editableQuantities[item.id] || item.qty}
                                onChange={(e) =>
                                  setEditableQuantities((prevQuantities) => ({
                                    ...prevQuantities,
                                    [item.id]: e.target.value,
                                  }))
                                }
                              />
                            ) : (
                              item.qty
                            )}

                            <div
                              className="cursor-pointer ml-2"
                              onClick={() => toggleEditableMode(item.id)}
                            >
                              <RiEdit2Line />
                            </div>
                          </div>
                        </td>
                        <td>{item.available_qty}</td>

                        {/* <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                          <div className="flex items-center justify-center">
                            <label className="inline-flex items-center mr-4">
                              <input
                                type="checkbox"
                                name={`atp_${item.id}`}
                                className="form-radio h-5 w-5 text-[#06652e]"
                                checked={atp[item.id] === "Yes"}
                                onChange={() =>
                                  dispatch(
                                    setAtp({
                                      productId: item.id,
                                      value: "Yes",
                                    })
                                  )
                                }
                              />
                              <span className="ml-2">Yes</span>
                            </label>
                            <label className="inline-flex items-center">
                              <input
                                type="checkbox"
                                name={`atp_${item.id}`}
                                className="form-radio h-5 w-5 text-[#06652e]"
                                checked={atp[item.id] === "No"}
                                onChange={() =>
                                  dispatch(
                                    setAtp({
                                      productId: item.id,
                                      value: "No",
                                    })
                                  )
                                }
                              />
                              <span className="ml-2">No</span>
                            </label>
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div>
            {isLoading ? null : products.length === 0 ? (
              <p className="text-gray-400 text-center mb-4">
                No quantity available
              </p>
            ) : (
              <div>
                {/* {products.some(
                  (item) => parseInt(item.available_qty) < parseInt(item.qty)
                ) && (
                  <p className="text-red-500 mb-4">
                    Some products have insufficient quantity.
                  </p>
                )} */}
                <button
                  className={`px-4 py-4 rounded w-full ${
                    isProcessed
                      ? "bg-[#06652e] text-white font-normal"
                      : "bg-[#06652e] text-white font-normal"
                  }`}
                  onClick={handleClick}
                  // disabled={
                  //   !products.every(
                  //     (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
                  //   ) ||
                  //   !products.every(
                  //     (item) =>
                  //       parseInt(item.available_qty) >= parseInt(item.qty)
                  //   ) ||
                  //   isProcessed
                  // }
                  // style={
                  //   !products.every(
                  //     (item) => atp[item.id] === "Yes" || atp[item.id] === "No"
                  //   ) ||
                  //   !products.every(
                  //     (item) =>
                  //       parseInt(item.available_qty) >= parseInt(item.qty)
                  //   ) ||
                  //   isProcessed
                  //     ? { opacity: 0.5, cursor: "not-allowed" }
                  //     : {}
                  // }
                >
                  {isProcessed ? "Processed" : "Send Order"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmergencyOrder;

//  className={`py-4 px-2 sm:px-4 md:px-6 lg:px-8 ${
//                       parseInt(item.available_qty) >= parseInt(item.qty)
//                         ? "text-green-500"
//                         : "text-red-500"
//                     }`}
